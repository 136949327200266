<template>
<div class="swiper">
  <div class="swiper-box">
    <el-carousel class="show-up-md" :interval="5000" arrow="never" :autoplay="false">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <template v-if="screenWidth > 1920">
          <img :src="item.image_xxl" class="banner-img" />
        </template>
        <template v-if="screenWidth > 1440 && screenWidth <= 1920">
          <img :src="item.image_xxl" class="banner-img" />
        </template>
        <template v-if="screenWidth <= 1440">
          <img :src="item.image_lg" class="banner-img" />
        </template>
        <div class="swiper-description">
          <h2 class="swiper-title">{{ item.title }}</h2>
          <p class="swiper-desc">{{ item.desc_first }}</p>
          <p class="swiper-desc">{{ item.desc_second }}</p>
          <div class="start-btn swiper-start-btn" @click="handleClickStart()" @mouseenter="item.hover = true" @mouseleave="item.hover = false">
            <span>立即开始</span>
            <img class="start-btn-icon" :src="item.hover ? item.icon_hover_url : item.icon_url"/>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-carousel class="show-down-and-md" :interval="5000" arrow="never" :autoplay="false">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <div class="swiper-description">
          <p class="swiper-title">{{ item.title }}</p>
          <p class="swiper-desc">{{ item.desc_first }}</p>
          <p class="swiper-desc">{{ item.desc_second }}</p>
          <div class="start-btn swiper-start-btn" @click="handleClickStart()" @mouseenter="item.hover = true" @mouseleave="item.hover = false">
            立即开始
            <img class="start-btn-icon" :src="item.hover ? item.icon_hover_url : item.icon_url"/>
          </div>
        </div>
        <template v-if="screenWidth > 900">
          <img :src="item.image_md" class="banner-img" />
        </template>
        <template v-else-if="screenWidth > 600 && screenWidth <= 900">
          <img :src="item.image_sm" class="banner-img" />
        </template>
        <template v-if="screenWidth <= 600">
          <img :src="item.image_xs" class="banner-img" />
        </template>
      </el-carousel-item>
    </el-carousel>
  </div>
  <FastRegister></FastRegister>
</div>
</template>

<script>
import {
  mapGetters
} from 'vuex';
import FastRegister from '../register/index'
export default {
  name: 'swiperBox',
  components: {
    FastRegister
  },
  computed: {
    ...mapGetters(['screenWidth'])
  },
  data() {
    return {
      phone: '',
      bannerList: [{
          id: 1,
          image_xxl: require("../../assets/image/index/banner/banner01-xxl.png"),
          image_xl: require("../../assets/image/index/banner/banner01-xl.png"),
          image_lg: require("../../assets/image/index/banner/banner01-lg.png"),
          image_md: require("../../assets/image/index/banner/banner01-md.png"),
          image_sm: require("../../assets/image/index/banner/banner01-sm.png"),
          image_xs: require("../../assets/image/index/banner/banner01-xs.png"),
          title: "专为AI而生的高性能云",
          desc_first: "经济，高效且专业的高性能GPU云。",
          desc_second: "开发，训练，扩展AI应用程序。",
          btn_text: "立即开始",
          hover: false,
          icon_url: require("../../assets/image/public/direction_right.svg"),
          icon_hover_url: require("../../assets/image/public/direction_right_hover.svg"),
        },
        {
          id: 2,
          image_xxl: require("../../assets/image/index/banner/banner01-xxl.png"),
          image_xl: require("../../assets/image/index/banner/banner01-xl.png"),
          image_lg: require("../../assets/image/index/banner/banner01-lg.png"),
          image_md: require("../../assets/image/index/banner/banner01-md.png"),
          image_sm: require("../../assets/image/index/banner/banner01-sm.png"),
          image_xs: require("../../assets/image/index/banner/banner01-xs.png"),
          title: "专为AI而生的高性能云",
          desc_first: "经济，高效且专业的高性能GPU云。",
          desc_second: "开发，训练，扩展AI应用程序。",
          btn_text: "立即开始",
          hover: false,
          icon_url: require("../../assets/image/public/direction_right.svg"),
          icon_hover_url: require("../../assets/image/public/direction_right_hover.svg"),
        },
        {
          id: 3,
          image_xxl: require("../../assets/image/index/banner/banner01-xxl.png"),
          image_xl: require("../../assets/image/index/banner/banner01-xl.png"),
          image_lg: require("../../assets/image/index/banner/banner01-lg.png"),
          image_md: require("../../assets/image/index/banner/banner01-md.png"),
          image_sm: require("../../assets/image/index/banner/banner01-sm.png"),
          image_xs: require("../../assets/image/index/banner/banner01-xs.png"),
          title: "专为AI而生的高性能云",
          desc_first: "经济，高效且专业的高性能GPU云。",
          desc_second: "开发，训练，扩展AI应用程序。",
          btn_text: "立即开始",
          hover: false,
          icon_url: require("../../assets/image/public/direction_right.svg"),
          icon_hover_url: require("../../assets/image/public/direction_right_hover.svg"),
        }
      ]
    }
  },
  methods: {
    handleClickStart() {
      let url = this.$config.basicUrl + "/user/login";
      window.open(url, "_blank");
    }
  },
}
</script>
